import React, {useEffect, useState} from 'react';
import {Button, Form, Spinner} from "react-bootstrap";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {PUBLISH_URL, USER_URL} from "../../utilities/urls";
import axios from "axios";
import Youtube from "./youtube";
import youTube from "../../assets/images/youtube.png";
import Card from "../common/card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import {faCheck, faCircleExclamation} from "@fortawesome/free-solid-svg-icons";

const Publish = ({postId, isVideo}) => {

    const [socialAccountStatus, setSocialAccountStatus] = useState(null);
    const [statusSpinner, setStatusSpinner] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [publishStatus, setPublishStatus] = useState(null);

    const getSocialAccountStatus = () => {
        const options = {
            method: 'GET',
            url: USER_URL + '/v1/social',
        };

        setStatusSpinner(true);

        axios.request(options).then(function (response) {
            setSocialAccountStatus(response.data.data);
            setStatusSpinner(false);
        }).catch(function (error) {
            setStatusSpinner(false);
            console.error(error);
        });
    };

    React.useEffect(() => {
        getSocialAccountStatus();
        getPublishStatus();
    }, []);

    const onFormSubmit = (e) => {
        e.preventDefault();
        setShowSpinner(true);

        const formData = new FormData(e.target);

        const options = {
            method: 'POST',
            url: PUBLISH_URL + '/v1/publish',
            data: {
                postId: postId,
                youTube: formData.get("youTube") === "on",
                youTubeCategory: 1
            }
        };

        axios.request(options).then(function (response) {
            setPublishStatus(response.data.data);
            setStatusSpinner(false);
        }).catch(function (error) {
            setStatusSpinner(false);
            console.error(error);
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => publishStatus?.youTube?.status === "In Progress" && getPublishStatus(), 1000)
        return () => clearTimeout(timer)
    }, [publishStatus])

    const getPublishStatus = () => {
        const options = {
            method: 'GET',
            url: PUBLISH_URL + `/v1/publish/${postId}/status`,
        };

        axios.request(options).then(function (response) {
            setPublishStatus(response.data.data);
        }).catch(function (error) {
            console.error(error);
        });
    };

    return (
        <GoogleOAuthProvider clientId="187512437830-6j3icgc3l6sdaik10at9ci1t951u7b3f.apps.googleusercontent.com">
            <Card className="card-block card-stretch card-height" id={postId}>
                <Card.Body>
                    <Form onSubmit={onFormSubmit} className="mx-3">
                        {
                            statusSpinner ?
                                <Spinner animation="border"/> :

                                <Form.Group className="mb-3" controlId="youTube">
                                    {
                                        (socialAccountStatus?.youTube && !socialAccountStatus?.youTubeTokenExpired) ?
                                            (
                                                !publishStatus?.youTube ?
                                                    <Form.Check className="mt-2 pt-1" title="YouTube">
                                                        <Form.Check.Input type="checkbox" className="me-2 hover-hand"
                                                                          name="youTube" style={{marginTop: "1.5rem"}}/>
                                                        <Form.Check.Label className="d-flex flex-column hover-hand"
                                                                          style={{fontSize: "0.8rem"}}>
                                                            <img src={youTube} alt="YouTube" style={{width: "3rem"}}/>
                                                            YouTube
                                                        </Form.Check.Label>
                                                    </Form.Check> :
                                                    (
                                                        <div className="d-flex flex-column hover-hand"
                                                             style={{maxWidth: "3rem"}}>
                                                            <img src={youTube} alt="YouTube" className="my-2"/>
                                                            {
                                                                publishStatus?.youTube.status === "In Progress" ?
                                                                    <Spinner animation="border"/> :
                                                                    publishStatus?.youTube.status === "Complete" ?
                                                                        <FontAwesomeIcon icon={faCheck}
                                                                                         style={{fontSize: "1.5rem"}}/> :
                                                                        <FontAwesomeIcon icon={faCircleExclamation}
                                                                                         style={{fontSize: "1.5rem"}}
                                                                                         title={publishStatus?.youTube.message}/>
                                                            }

                                                        </div>
                                                    )
                                            ) :
                                            <Youtube setSocialAccountStatus={setSocialAccountStatus}/>
                                    }
                                </Form.Group>
                        }
                        <div className="d-flex justify-content-end">
                            {
                                showSpinner ? <Spinner animation="border"/> :
                                    <Button to="#" type="submit" name="send" style={{background: "none", border: "none"}}>
                                        <FontAwesomeIcon icon={faPaperPlane} style={{fontSize: "1.5rem"}}/>
                                    </Button>
                            }
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </GoogleOAuthProvider>
    );
}

export default Publish;