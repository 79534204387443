import React from 'react'
import VerticalNav from './vertical-nav'
import Sidebar from "react-sidebar";

const SideBar = ({sidebarOpen, sidebarDocked, setSidebarOpen}) => {
    return (
        <Sidebar
            open={sidebarOpen}
            docked={sidebarDocked}
            onSetOpen={setSidebarOpen}
            sidebar={<div className="sidebar-base"><VerticalNav setSidebarOpen={setSidebarOpen}/></div>}
            sidebarClassName="sidebar"
            styles={sidebarDocked ? {
                sidebar: {
                    zIndex: "11",
                    position: "fixed",
                    top: "80px"
                },
                overlay: {
                    zIndex: "10"
                }
            } : {
                sidebar: {
                    zIndex: "11",
                    position: "fixed",
                    bottom: "3rem"
                },
                overlay: {
                    zIndex: "10"
                }
            }}
        >
            &nbsp;
        </Sidebar>
    )
}

export default SideBar
