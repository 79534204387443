import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Button, Col, Form, Spinner} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {formatDate} from "../../utilities/util";

const Chat = ({space, spaceNames, messages, sendMessage, userProfile}) => {
    const lastMessageRef = useRef();

    const [message, setMessage] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        lastMessageRef.current?.scrollIntoView();
    }, [messages]);

    useEffect(() => {
        if (space) {
            setDisplayName(spaceNames.find(a => a.name === space).displayName);
        }
    }, [space]);

    const onSendMessage = (e) => {
        e.preventDefault();
        sendMessage(message, () => setMessage(''));
    }

    return (
        <Col lg={8} className=" chat-data p-0 chat-data-right">
            {
                space ?
                    <>
                        <div className="chat-head">
                            <header
                                className="d-flex justify-content-between align-items-center pt-3 ps-3 pe-3 pb-3">
                                <div className="d-flex align-items-center">
                                    <div className="sidebar-toggle">
                                        <FontAwesomeIcon icon={faArrowLeft}
                                                         style={{marginTop: "-10px", fontSize: "1.2rem"}}/>
                                    </div>
                                    <h5 className="mb-0">{displayName}</h5>
                                </div>
                            </header>
                        </div>
                        <div className="chat-content scroller">
                            {
                                messages?.map((message, index) => (
                                    userProfile?.userId === message.createdBy ?
                                        <div className="chat d-flex other-user" key={index}>
                                            <div className="chat-user">
                                                <Link className="avatar m-0" to="">
                                                    <img src={message.creator.avatarUrl} alt="avatar"
                                                         className="avatar-35 rounded-circle"/>
                                                </Link>
                                                <span className="chat-time">{formatDate(message.createdAt)}</span>
                                            </div>
                                            <div className="chat-detail">
                                                <div className="chat-message">
                                                    <p>{message.message}</p>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="chat chat-left" key={index}>
                                            <div className="chat-user">
                                                <Link className="avatar m-0" to="">
                                                    <img src={message.creator.avatarUrl} alt="avatar"
                                                         className="avatar-35 rounded-circle"/>
                                                </Link>
                                                <span className="chat-time">{formatDate(message.createdAt)}</span>
                                            </div>
                                            <div className="chat-detail">
                                                <div className="chat-message">
                                                    <p>{message.message}</p>
                                                </div>
                                            </div>
                                        </div>
                                ))
                            }
                            <div ref={lastMessageRef}/>
                        </div>
                        <div className="chat-footer p-3">
                            <Form onSubmit={onSendMessage}>
                                <div className="d-flex flex-column">
                                    <div className="text-end">
                                        {
                                            `${message.length}/150`
                                        }
                                    </div>
                                    <div className="d-flex">
                                        <Form.Control type="text" style={{paddingRight: 3 + "rem"}}
                                                      placeholder="Type your message" value={message}
                                                      onChange={(e) => setMessage(e.target.value)}
                                                      maxLength={150}/>
                                        {
                                            showSpinner ?
                                                <Spinner className="comment-send-spinner"/> :
                                                <Button to="#" type="submit" name="send"
                                                        style={{background: "none", border: "none"}}
                                                        className="paperplane-submit feather-icon">
                                                    <FontAwesomeIcon icon={faPaperPlane}/>
                                                </Button>
                                        }
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </> :
                    <h5>
                        Select a space to view messages
                    </h5>
            }
        </Col>
    )
}
export default Chat;    