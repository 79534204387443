import React, {useEffect} from 'react';
import {Col, Form, Nav} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

const SpacesNav = ({onSearch, space, setSpace, spaceNames}) => {
    const {hash} = useLocation();

    useEffect(() => {
        if (!space) {
            setSpace(hash.replace('#', ''));
        }
    }, []);

    return <Col lg="4" className="chat-data-left scroller show p-3">
        <div className="chat-search">
            <Form className="mt-4">
                <div className="d-flex position-relative">
                    <Form.Control type="text"
                                  style={{paddingRight: 3 + "rem"}}
                                  placeholder="Search"/>
                    <Link to="#" className="spaces-search feather-icon">
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    </Link>
                </div>
            </Form>
        </div>
        <div className="chat-sidebar-channel scroller mt-2">
            <h5 className="mt-2">Spaces</h5>
            <Nav variant="pills" className="iq-chat-ui nav flex-column">
                {
                    spaceNames?.map(spaceName => (
                        <Nav.Item as="li" key={spaceName.name}>
                            <Link
                                className={`${hash === '#' + spaceName.name ? 'active' : ''} nav-link`}
                                aria-current="page"
                                onClick={() => setSpace(spaceName.name)}
                                to={`/spaces/#${spaceName.name}`}>
                                <div
                                    className="item-name ps-2">{spaceName.displayName}</div>
                            </Link>
                        </Nav.Item>
                    ))
                }
            </Nav>
        </div>
    </Col>;
};

export default SpacesNav;