import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const tenorAxiosInstance = axios.create();

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const getAge = (date) => {
    const strippedDate = date.replace('T', ' ') + ' UTC';
    const jsDate = dayjs.utc(strippedDate.substring(0, strippedDate.indexOf(".")));

    return dayjs(jsDate).fromNow();
}
export const formatDate = (date) => {
    const strippedDate = date.replace('T', ' ') + ' UTC';

    return dayjs(strippedDate).format('h:mm a');
}