import {useEffect, useRef, useState} from 'react';
import {io} from 'socket.io-client';
import {SOCKETS_URL, SPACES_URL} from "../../utilities/urls";
import axios from "axios";
import {toast} from "react-toastify";

const ClientSocket = ({accessToken, space}) => {
    const socketRef = useRef();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (space) {
            fetchMessages();

            socketRef.current = io(SOCKETS_URL, {
                extraHeaders: {
                    "token": accessToken
                },
                withCredentials: true
            });

            socketRef.current.emit('join', space, error => {
                if (error) {
                    console.log(error);
                    toast.error("Failed to send message", {
                        position: "bottom-right",
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true
                    });
                }
            });

            socketRef.current.on('message', message => {
                setMessages(messages => [...messages, message]);
            });

            socketRef.current.on('reload', message => {
                fetchMessages();
            });

            return () => {
                socketRef.current.emit('leave');
                socketRef.current.disconnect();
            };
        }
    }, [socketRef, space]);

    const fetchMessages = () => {
        axios.get(`${SPACES_URL}/spaces/${space}/messages`).then((response) => {
            setMessages(response.data);
        });
    }

    const sendMessage = (message, callback) => {
        if (space) {
            socketRef.current.emit('sendMessage', {space, message}, error => {
                if (error) {
                    console.log(error);
                    toast.error("Failed to send message", {
                        position: "bottom-right",
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true
                    });
                }
            });
            callback && callback();
        }
    };

    return {messages, sendMessage};
};

export default ClientSocket;