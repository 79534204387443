import React from 'react';
import {useGoogleLogin} from "@react-oauth/google";
import {USER_URL} from "../../utilities/urls";
import axios from "axios";
import {Link} from "react-router-dom";
import youTube from "../../assets/images/youtube.png";

const Youtube = ({setSocialAccountStatus}) => {
    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            const options = {
                method: 'POST',
                url: USER_URL + '/v1/social/youtube',
                data: {
                    code: codeResponse.code
                }
            };

            axios.request(options).then(function (response) {
                setSocialAccountStatus(response.data.data);
            }).catch(function (error) {
                console.error(error);
            });
        },
        flow: 'auth-code',
        scope: 'email profile openid https://www.googleapis.com/auth/youtube.upload',
        prompt: 'consent'
    });

    return <div>
        <Link onClick={login} to="#" className="d-flex flex-column">
            <img src={youTube} alt="YouTube" style={{width: "3rem"}}/>
            Sign in
        </Link>
    </div>;
};

export default Youtube;