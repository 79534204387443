import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {Nav} from 'react-bootstrap'
import {useSelector} from "react-redux";
import {faChartLine, faHouse, faRankingStar, faRocket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbsUp} from "@fortawesome/free-regular-svg-icons";

const defaultAvatar = "https://media.postup.in/avatar/user_profile_2.jpg";

const VerticalNav = React.memo(({setSidebarOpen}) => {
    let location = useLocation();
    let navigate = useNavigate();

    const categories = useSelector(state => state.categories);
    const spaceNames = useSelector(state => state.spaceNames);
    const topCreators = useSelector(state => state.topCreators);

    const handleClick = (url) => {
        setSidebarOpen(false);

        navigate(url);
    }

    return (
        <React.Fragment>
            <div>
                {/*<div className="card-center-align signup-card mt-3 mb-3">
                    <h4 className="card-title">New to PostUp?</h4>
                    <div>
                        Sign up to see more content!
                    </div>
                    <Button className="mt-5">Sign up</Button>
                </div>*/}
                <div className="mb-3 mt-4">
                    <h6 className="card-title ms-3">Top Contributors Of The Week</h6>
                </div>
                <div>
                    <ul className="media-story list-inline m-0 p-0">
                        {
                            topCreators.map(top =>
                                <Nav.Item as="li" className="mb-2 ms-1" key={top.creator.userId}>
                                    <Link onClick={() => handleClick(`/creator/${top.creator.userId}`)} to="#"
                                          key={top.creator.userId} className="d-flex align-items-center">
                                        <img src={top.creator.avatarUrl || defaultAvatar} alt="avatar"
                                             className="avatar rounded-circle img-fluid"/>
                                        <div className="ms-2">{top.creator.displayName}</div>
                                    </Link>
                                </Nav.Item>
                            )
                        }
                    </ul>
                </div>
            </div>
            <div className="mt-4">
                <Nav.Item as="li" className="p-0">
                    <Link onClick={() => handleClick("/")} to="#"
                          className={`${location.pathname === '/' ? 'active' : ''} nav-link`}>
                        <FontAwesomeIcon icon={faHouse}/> &nbsp; Home
                    </Link>
                </Nav.Item>
                <Nav.Item as="li" className="p-0">
                    <Link onClick={() => handleClick("/top")} to="#"
                          className={`${location.pathname === '/top' ? 'active' : ''} nav-link`}>
                        <FontAwesomeIcon icon={faRankingStar}/> &nbsp; Top
                    </Link>
                </Nav.Item>
                <Nav.Item as="li" className="p-0">
                    <Link onClick={() => handleClick("/spaces")} to="#"
                          className={`${location.pathname === '/spaces' ? 'active' : ''} nav-link`}>
                        <FontAwesomeIcon icon={faRocket}/> &nbsp; Spaces
                    </Link>
                </Nav.Item>
            </div>

            <div>
                <div className="mb-1 mt-4">
                    <h6 className="card-title ms-3">Categories</h6>
                </div>
                {
                    location.pathname === '/spaces' ?
                        spaceNames?.map(spaceName => (
                            <Nav.Item as="li" key={spaceName.name}>
                                <Link
                                    className={`${location.pathname === '/space/' + spaceName.name ? 'active' : ''} nav-link`}
                                    aria-current="page" onClick={() => handleClick(`/space/${spaceName.name}`)}
                                    to="#">
                                    <div className="item-name">{spaceName.displayName}</div>
                                </Link>
                            </Nav.Item>
                        )) :
                        categories?.map(category => (
                            <Nav.Item as="li" key={category.code}>
                                <Link
                                    className={`${location.pathname === '/category/' + category.code ? 'active' : ''} nav-link`}
                                    aria-current="page" onClick={() => handleClick(`/category/${category.code}`)}
                                    to="#">
                                    <div className="item-name">{category.name}</div>
                                    {/*<FontAwesomeIcon icon={faThumbsUp} style={{fontSize: 1.3 + "rem"}}/>*/}
                                </Link>
                            </Nav.Item>
                        ))
                }
            </div>
        </React.Fragment>
    )
})

export default VerticalNav
